.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

select {

  /* styling */
  background-color: white;
  border: thin solid grey;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}


/* arrows */

select.classic {
  background-image:
    linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 3.5em;
  background-repeat: no-repeat;
}

select.classic:focus {
  background-image:
    linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position:
    calc(100% - 15px) 1em,
    calc(100% - 20px) 1em,
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: blue;
  outline: 0;
}

.btn-1 {
  text-align: center;
  display: inline-block;
  padding: 9px 40px;
  border: 1px solid grey;
  cursor: pointer;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  margin: 0 10px;
  background: #4CAF50 !important;
  color: #fff;
  text-transform: uppercase;
}

.btn-1:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: #fff;
  left: 0;
  bottom: 0;
  transition: all .3s;
  opacity: 0.3;
}

.btn-1:hover:before {
  width: 100%;
}

.headertop {
  display: flex;
  place-content: flex-end;
  /* justify-content: center; */
  align-items: center;
  padding-top: 10px;
}

.headerbottom {
  display: flex;
  place-content: flex-end;
  /* justify-content: center; */
  align-items: center;
  padding: 10px 0px;
}


.previous {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  background: green;
  color: #fff;
  margin: 5px;
}

.next {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  background: green;
  color: #fff;
  margin: 5px;
}

button:disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;

}

.previous:hover:enabled,
.next:hover:enabled {
  text-decoration: none;
  background-color: darkgreen;
}

.header_top {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}


ul.dashboard {
  position: relative;
  padding: 10px 8px;
  width: 310px;
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin: 10px 10px;
}

ul.dashboard li {
  position: relative;
  list-style: none;
}

ul.dashboard li:hover::before {
  background-color: #188aff;
}

ul.dashboard li a {
  text-decoration: none;
  display: flex;
  color: #333;
}

ul.dashboard li a .icon {
  width: 60px;
  height: 40px;
  text-align: center;
  overflow: hidden;
  margin: 10px;
}

ul.dashboard li a .icon .fa {
  width: 100%;
  height: 100%;
  line-height: 40px;
  font-size: 34px;
  color: #333;
  transition: 0.5s;
}

ul.dashboard li a .icon .fa:last-child {
  color: #188aff;
}

ul li a:hover .icon .fa {
  transform: translateY(-100%);
}

ul.dashboard li a:hover .icon .fa:last-child {
  transform: translateY(-100%);
}

ul.dashboard li a .name {
  position: relative;
  top: 0px;
  height: 20px;
  width: 100%;
  display: block;
  overflow: hidden;
}

ul.dashboard li a .name span {
  display: block;
  position: relative;
  color: #333;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  transition: 0.5s;
  text-align: center;
}

ul.dashboard li a .name span::before {
  content: attr(data-text);
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  color: #188aff;
}

ul.dashboard li a:hover .name span {
  transform: translateY(20px);
}

.space {
  display: flex;
  padding: 1px;
  align-items: center;
}

.text {
  font-size: 15px;
  justify-content: center;
  place-content: center;
  padding: 10px;
  width: 235px;
}

.text1 {
  font-size: 15px;
  justify-content: center;
  place-content: center;
  padding: 10px;
  width: 95px;
}

.headertop1 {
  display: flex;
  align-items: center;
}

.datefield {
  font-size: 15px;
  display: flex;
}

.react-date-picker__wrapper {
  width: 235px;
}

.space input {
  font-size: 16px;
}

.modal-title {
  font-size: 18px;
  padding: 0px;
  text-transform: uppercase;
}

.modal-footer button {
  font-size: 15px;
}

.modal-header .close {
  font-size: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}

.btncss {
  width: 80px;
  height: 35px;
  font-size: 10px;
  /* margin-top: 5px; */
}

.updateSettingsBtn {
  width: 120px;
  height: 30px;
  font-size: 14px;
}

.ag-row .ag-cell {
  display: flex;
  /* justify-content: center;  */
  align-items: center;
}

.Polaris-Tabs__TabContainer {

  margin: 0 2px;
  background-color: #f7f7f7;
  transition: background-color 0.2s, color 0.2s;
}

.Polaris-Tabs__Tab--selected .Polaris-Tabs__Title {
  color: white !important;

}

.Polaris-Tabs__Tab--selected {
  background: #2CC185 !important;
}

.loader5 {
  width: 30px;
  height: 30px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #D0DBE6;
  border-bottom-color: #018E8F;
  border-left-color: #D0DBE6;
  border-right-color: #018E8F;
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader5 1s ease-in-out infinite;
}

@keyframes loader5 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader5 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.transprant {
  background: transparent;
  border: none;
}

.facss {
  color: #61dafb;
  padding-right: 20px;
}

.loader6 {
  width: 30px;
  height: 30px;
  display: inline-block;
  padding: 0px;
  border-radius: 100%;
  border: 5px solid;
  border-top-color: #D0DBE6;
  border-bottom-color: #018E8F;
  border-left-color: #D0DBE6;
  border-right-color: #018E8F;
  -webkit-animation: loader4 1s ease-in-out infinite;
  animation: loader5 1s ease-in-out infinite;
  z-index: 8000;
  position: absolute;
  left: 50%;
  top: 52%;
}

@keyframes loader6 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader6 {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.showMessage {
  color: red;
  font-size: 14px;
  display: flex;
  place-content: flex-end;
  align-items: center;
  padding: 10px;
}

.showImportMessage {
  color: red;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  padding-left: 0;
}

.checkboxc {
  display: flex;
  align-items: center;
}

input[type=checkbox],
input[type=radio] {
  width: 20px;
  height: 30px;
}

.pricelog-model-header .Polaris-RadioButton__Input,
.pricelog-model-header .Polaris-RadioButton__Input {
  width: 15px !important;
  height: 15px !important;
  position: relative !important;
}

fieldset {
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: threedface;
  border-image: initial;
  margin-bottom: 15px;
}

legend {
  display: block;
  -webkit-padding-start: 2px;
  padding-inline-start: 2px !important;
  -webkit-padding-end: 2px !important;
  padding-inline-end: 2px !important;
  border: none;
  border-color: initial;
  border-image: none;
  border-image: initial;
  width: auto !important;
  font-size: 15px;
}

.react-date-picker__wrapper {
  width: 180px;
  height: 30px;
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: fit-content !important;
  }

}

.headerbottom.searchbar {
  place-content: flex-start !important;
  width: 100%;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
}

.searchtinput {
  width: 180px;
}

.headertopstudent {
  display: flex;
  place-content: space-between;
  align-items: baseline;
  padding: 10px 0px;
}

.headerbottom.searchbarstudent {
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
}

button.export.btn.btn-primary {
  width: 100px;
  font-size: 14px;
}

.importReinvite.btn.btn-primary {
  font-size: 14px;
}

.reinviteModal .reinviteExcelUploadNote {
  font-size: 12px;
  color: red;
}

.inputbox button.btn.btn-primary {
  font-size: 12px;
  margin-top: -3px;
  height: 27px;
}

.inputError {
  color: red;
  display: flex;
  align-items: flex-start;
  justify-content: initial;
  padding-left: 235px;
}

input.boderred {
  border: 2px solid red;
}

.modal-90w {
  min-width: 60vw;
}

.published {
  color: green;
}

.draft {
  color: grey;
}

.export_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.headertop_eventlist {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.header_search_import {
  justify-content: space-between;
  align-items: flex-start;
}

.importsubmit {
  width: 95%;
  margin: 10px 0px 10px 0px;
}

.importsamplefile {
  padding-bottom: 10px;
}

.salsifyDisplayNameWrapper .ag-cell.ag-cell-auto-height.ag-cell-value.ag-cell-focus.ag-cell-inline-editing {
  margin: 10px 0;
}

.pageWrapper .ag-cell.ag-cell-auto-height.ag-cell-value.ag-cell-focus.ag-cell-inline-editing {
  margin: 10px 0;
}

.showMessageEmail {
  color: green;
  font-size: 14px;
  display: flex;
  place-content: flex-end;
  align-items: center;
  padding: 10px;
}

.returnsMenuWrapper {
  padding: 0 10px 10px;
}

.returnsMenuWrapper .noRecordsNote {
  border: 1px solid;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  padding: 20px;
  margin: 0 auto;
  font-size: 15px;
  border-radius: 4px;
}

.orderDetailsTable-returns table,
.orderDetailsTable-returns th,
.orderDetailsTable-returns td {
  border: 1px solid black;
  border-collapse: collapse;
}

.createReturns .lineItemsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* border: 1px solid #00000052; */
  padding: 8px;
  border-radius: 6px;
  align-items: center;
}

.lineItemsWrapper .lineItemReturnQty {
  border: 1px solid #00000052;
  border-radius: 4px;
  flex-basis: 20%;
}

.createReturns .lineItemsWrapper .Polaris-Icon.Polaris-Icon--colorBase.Polaris-Icon--applyColor {
  height: 30px;
  width: 30px;
}

.returnsMenuWrapper .downloadButton {
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
}

.createReturnsModel .submitBtn {
  width: 70px;
  margin-right: 5px;
}

.importprice-model-header .Polaris-RadioButton__Input {
  width: 15px !important;
  height: 15px !important;
  position: relative !important;
}



#submission-modal {
  font-family: Inter, -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
}

#submission-modal,
.modal-xl {
  max-width: calc(100% - 5orem) !important;
}

#submission-modal .modal-content {
  border-radius: 0px;
  background: #f1f1f1;
  border: none;
  box-shadow: 2px 14px 23px 2px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: 2px 14px 23px 2px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: 2px 14px 23px 2px rgba(0, 0, 0, 0.51);
}

.modalHeaderColor,
.moalBodyColor,
.modalFooterColor {
  background: #f1f1f1;
}

.image-section {
  padding-left: 10px;
  height: 600px;
}

.detail-view-modal {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.detail-section {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.detail-section--box {
  display: flex;
  background: #fff;
  margin-bottom: 15px;
  border-radius: 10px;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  border: 1px solid #80808033;
  box-shadow: 0rem .0625rem 0rem 0rem rgb(26 26 26 / 44%);
}


.detail-section--field {
  display: inline-block;
  padding: 2px;
  min-width: 30%;
}

.modalButton {
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 8px 25px;
  border: none !important;
}


.dataLabel {
  font-size: 1.2rem;
  color: rgba(97, 97, 97, 1);
}

.approve {
  background: rgba(48, 48, 48, 1) !important;
  font-weight: 600;
}

.approve:hover {
  background: rgba(26, 26, 26, 1) !important;
}

.deny {
  background: rgba(142, 11, 33, 1) !important;
}

.deny:hover {
  background: rgba(163, 10, 36, 1) !important;
}

.modalFooter {
  display: flex;
  justify-content: space-between;
}

.dataValue {
  font-size: 1.3rem;
  font-weight: 400;
}

.assignmentType .Polaris-Select {
  width: 150px !important;
}

.modalFooter .Polaris-Select {
  background: #fff;
  margin-right: 5px;
  border-radius: 5px;
  box-shadow: 0rem .0625rem 0rem 0rem rgb(26 26 26 / 44%);
  border: 1px solid #80808033;
}

.headerDropdown {
  display: flex;
  margin-right: 15px;
}

.dropdownTitle {
  display: flex;
  padding-right: 10px;
  width: max-content;
  align-items: center;
}

.loader{
  top: 35%;
  left: 50%;
  position: absolute;
}

.modalPopupWithoutData{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
}

.stats-value {
  display: block;
  padding: 15px;
  font-size: 2.8rem;
  font-weight: 600;
  color: #55a6dd;
}

.stats-note {
  display: block;
  padding: 2px;
}

.Polaris-IndexTable__TableCell {
  position: initial !important;
}