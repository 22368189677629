.btn-container {
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: #fff;
	border-radius: 8px 0 8px;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
}

button {
	border: none;
	color: #737373;
	background-color: #fff;
	padding: 10px;
	cursor: pointer;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
